<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}

.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.report-summary-table,
.credit-account-info-table {
  width: 100%;
  border-collapse: collapse;
}
.report-summary-table-cell {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.credit-account-info-table > tr > th {
  font-size: 13px;
  background-color: #cfd8dc9f;
}
.credit-account-info-table > tr > td {
  font-size: 13px;
  color: #000000;
  padding: 5px 0;
  /* font */
}
.credit-account-info-table > tr:hover {
  background-color: #e2e2e24a;
}
.loading-text {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.dot-1 {
  position: relative;
  animation: dot 500ms linear alternate-reverse infinite;
}
.dot-2 {
  position: relative;
  animation: dot 500ms 150ms linear alternate-reverse infinite;
}
.dot-3 {
  position: relative;
  animation: dot 500ms 250ms linear alternate-reverse infinite;
}
.dot-4 {
  position: relative;
  animation: dot 500ms 300ms linear alternate-reverse infinite;
}
@keyframes dot {
  from {
    opacity: 1;
    /* top: 0px; */
  }
  to {
    opacity: 0;
    /* top: -15px; */
  }
}
.text-align-center {
  text-align: center;
}
</style>
<template>
  <div>
    <div v-if="page_loader" class="loading-text mb-3">
      Loading<span class="dot-1">●</span>
      <span class="dot-2">●</span>
      <span class="dot-3">●</span>
      <span class="dot-4">●</span>
    </div>
    <div class="mb-8" v-else>
      <template v-if="cibil.INProfileResponse">
        <v-row>
          <v-col cols="12" class="pb-0">
            <div
              class="pl-3 pt-2 pr-2  text-body-1 font-weight-bold primary_2--text d-flex justify-space-between"
            >
              CIBIL Report Details
              <v-btn
                outlined
                color="primary_2"
                depressed
                small
                @click="gotToCompleteDetails()"
              >
                View All Details
              </v-btn>
            </div></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="8 pt-0">
            <v-row class="px-3 py-4">
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <p class="field-title">
                      Report Number
                    </p>
                    <p class="field-content">
                      {{
                        checkForValue(
                          cibil.INProfileResponse.CreditProfileHeader
                            .ReportNumber
                        )
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <p class="field-title">
                      Report Date
                    </p>
                    <p class="field-content">
                      {{
                        checkForDateValue(
                          cibil.INProfileResponse.CreditProfileHeader.ReportDate
                        )
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <p class="field-title">
                      Name
                    </p>
                    <p class="field-content">
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.First_Name
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Middle_Name1
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Middle_Name2
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Middle_Name3
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Last_Name
                        )
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <p class="field-title">Mobile Phone</p>
                    <p class="field-content">
                      {{
                        checkForValue(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Date_Of_Birth_Applicant
                        )
                      }}
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <p class="field-title">
                      Address
                    </p>
                    <p class="field-content">
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsFlatNoPlotNoHouseNo
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsBldgNoSocietyName
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsRoadNoNameAreaLocality
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsCity
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsLandmark
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsState
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsPINCode
                        )
                      }}
                      {{
                        checkForValueEmpty(
                          cibil.INProfileResponse
                            .Current_Applicant_Address_DetailsCountry_Code
                        )
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <p class="field-title">Email</p>
                    <p class="field-content">
                      {{
                        checkForValue(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.EMailId
                        )
                      }}
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <p class="field-title">
                      Date Of Birth
                    </p>
                    <p class="field-content">
                      {{
                        checkForDateValue(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Date_Of_Birth_Applicant
                        )
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <p class="field-title">Gender</p>
                    <p class="field-content">
                      {{
                        checkForValueGender(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Gender_Code
                        )
                      }}
                    </p>
                  </v-col>
                </v-row>

                <!-- <v-row>
              <v-col cols="12">
                <p class="field-title">Email</p>
                <p class="field-content">
                  {{
                    checkForValue(
                      cibil.INProfileResponse.Current_Application
                        .Current_Application_Details.Current_Applicant_Details
                        .EMailId
                    )
                  }}
                </p>
              </v-col>
            </v-row> -->

                <v-row>
                  <v-col cols="6">
                    <p class="field-title">Aadhaar Number</p>
                    <p class="field-content">
                      {{
                        checkForValue(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.Universal_ID_Number
                        )
                      }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <p class="field-title">PAN</p>
                    <p class="field-content">
                      {{
                        checkForValue(
                          cibil.INProfileResponse.Current_Application
                            .Current_Application_Details
                            .Current_Applicant_Details.IncomeTaxPan
                        )
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="text-h1 font-weight-bold d-flex align-center">
            {{ checkForValue(cibil.INProfileResponse.SCORE.BureauScore) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
              class="pl-3 pt-2 mb-2 text-body-1 font-weight-bold primary_2--text"
            >
              Report Summary
            </div>
            <div class="px-3">
              <table class="report-summary-table">
                <tr class="font-weight-bold">
                  <td>Credit Account Summary</td>
                  <td>Current Balance Amount Summary</td>
                  <td>Credit Enquiry Summary</td>
                  <td>Non-Credit Enquiry Summary</td>
                </tr>
                <tr>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Total number of Accounts
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAIS_Account.CAIS_Summary
                              .Credit_Account
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Total Current Bal. amt
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAIS_Account.CAIS_Summary
                              .Total_Outstanding_Balance.Outstanding_Balance_All
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 7 days credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAPS.CAPS_Summary
                              .CAPSLast7Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 7 days non-credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.NonCreditCAPS
                              .NonCreditCAPS_Summary.NonCreditCAPSLast7Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Active Accounts
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAIS_Account.CAIS_Summary
                              .CreditAccountActive
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">SF/WD/WO/Settled amt</div>
                      <div class="field-content ">
                        NA
                        <!-- {{checkForValue(cibil.)}} -->
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 30 days credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAPS.CAPS_Summary
                              .CAPSLast30Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 30 days non-credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.NonCreditCAPS
                              .NonCreditCAPS_Summary.NonCreditCAPSLast30Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Closed Accounts
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAIS_Account.CAIS_Summary
                              .CreditAccountClosed
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">Secured Accounts amt</div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAIS_Account.CAIS_Summary
                              .Total_Outstanding_Balance
                              .Outstanding_Balance_Secured
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 90 days credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAPS.CAPS_Summary
                              .CAPSLast90Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 90 days non-credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.NonCreditCAPS
                              .NonCreditCAPS_Summary.NonCreditCAPSLast90Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        SF/WD/WO/Settled
                      </div>
                      <div class="field-content ">
                        <!-- {{checkForValue(cibil.)}} -->
                        NA
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">Unsecured Accounts amt</div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAIS_Account.CAIS_Summary
                              .Total_Outstanding_Balance
                              .Outstanding_Balance_UnSecured
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 180 days credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.CAPS.CAPS_Summary
                              .CAPSLast180Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="report-summary-table-cell">
                      <div class="field-title">
                        Last 180 days non-credit enquiries
                      </div>
                      <div class="field-content ">
                        {{
                          checkForValue(
                            cibil.INProfileResponse.NonCreditCAPS
                              .NonCreditCAPS_Summary.NonCreditCAPSLast180Days
                          )
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
              class="pl-3 pt-2 pb-3 text-body-1 font-weight-bold primary_2--text"
            >
              Summary: Credit Account Information
            </div>
            <div
              class="px-3"
              v-if="
                cibil.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS[0]
              "
            >
              <table class="credit-account-info-table">
                <tr>
                  <th></th>
                  <th>Ledger</th>
                  <th>Account Type</th>
                  <th>Account No.</th>
                  <th>Ownership</th>
                  <th>Date Reported</th>
                  <th>Account Status</th>
                  <th>Date Opened</th>
                  <th>
                    Santioned Amt/<br />
                    Highest Credit
                  </th>
                  <th>Current Balance</th>
                  <th>Amount Overdue</th>
                </tr>
                <tr
                  v-for="(item, index) in cibil.INProfileResponse.CAIS_Account
                    .CAIS_Account_DETAILS"
                  :key="index"
                >
                  <!-- <td class="text-caption">Acct {{index}}</td> -->
                  <td class="px-4">{{ index + 1 }}</td>
                  <td class="text-align-center">
                    {{ checkForValue(item.Subscriber_Name) }}
                  </td>
                  <td class="text-align-center">
                    {{ checkForAccountTypeValue(item.Account_Type) }}
                  </td>
                  <td class="text-align-center">
                    {{ checkForValue(item.Account_Number) }}
                  </td>
                  <td class="text-align-center">
                    <!-- {{checkForValue(item.Subscriber_Name)}} -->
                    NA
                  </td>
                  <td class="text-align-center">
                    {{ checkForDateValue(item.Date_Reported) }}
                  </td>
                  <td class="text-align-center">
                    {{ checkForAccountStatusValue(item.Account_Status) }}
                  </td>
                  <td class="text-align-center">
                    {{ checkForDateValue(item.Open_Date) }}
                  </td>
                  <td class="text-align-center">
                    {{
                      checkForValue(item.Highest_Credit_or_Original_Loan_Amount)
                    }}
                  </td>
                  <td class="text-align-center">
                    {{ checkForValue(item.Current_Balance) }}
                  </td>
                  <td class="text-align-center">
                    {{ checkForValue(item.Amount_Past_Due) }}
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <table class="credit-account-info-table">
                <tr>
                  <th></th>
                  <th>Ledger</th>
                  <th>Account Type</th>
                  <th>Account No.</th>
                  <th>Ownership</th>
                  <th>Date Reported</th>
                  <th>Account Status</th>
                  <th>Date Opened</th>
                  <th>
                    Santioned Amt/<br />
                    Highest Credit
                  </th>
                  <th>Current Balance</th>
                  <th>Amount Overdue</th>
                </tr>
                <tr>
                  <!-- <td class="text-caption">Acct {{index}}</td> -->
                  <td class="px-4">1</td>
                  <td>
                    {{
                      checkForValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Subscriber_Name
                      )
                    }}
                  </td>
                  <td>
                    {{
                      checkForAccountTypeValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Account_Type
                      )
                    }}
                  </td>
                  <td>
                    {{
                      checkForValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Account_Number
                      )
                    }}
                  </td>
                  <td>
                    <!-- {{checkForValue(cibil.INProfileResponse.CAIS_Account.CAIS_Account_DETAILS.Subscriber_Name)}} -->
                    NA
                  </td>
                  <td>
                    {{
                      checkForDateValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Date_Reported
                      )
                    }}
                  </td>
                  <td>
                    {{
                      checkForAccountStatusValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Account_Status
                      )
                    }}
                  </td>
                  <td>
                    {{
                      checkForDateValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Open_Date
                      )
                    }}
                  </td>
                  <td>
                    {{
                      checkForValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS
                          .Highest_Credit_or_Original_Loan_Amount
                      )
                    }}
                  </td>
                  <td>
                    {{
                      checkForValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Current_Balance
                      )
                    }}
                  </td>
                  <td>
                    {{
                      checkForValue(
                        cibil.INProfileResponse.CAIS_Account
                          .CAIS_Account_DETAILS.Amount_Past_Due
                      )
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="text-center text-body-2 pt-3">No data available</div>
      </template>
      <CibilAllDetailsDialog
        ref="cibil_all_details_dialog"
      ></CibilAllDetailsDialog>
    </div>
  </div>
</template>
<script>
// import cibil_res from "../new-customer/cibil-response-2.js";
// import cibil_res from "../new-customer/cibil-response.js";

export default {
  data() {
    return {
      cibil_data: [],
      page_loader: false,
      account_type: [
        { value: "01", text: "AUTO LOAN" },
        { value: "02", text: "HOUSING LOAN" },
        { value: "03", text: "PROPERTY LOAN" },
        { value: "04", text: "LOAN AGAINST SHARES/SECURITIES" },
        { value: "05", text: "PERSONAL LOAN" },
        { value: "06", text: "CONSUMER LOAN" },
        { value: "07", text: "GOLD LOAN" },
        { value: "08", text: "EDUCATIONAL LOAN" },
        { value: "09", text: "LOAN TO PROFESSIONAL" },
        { value: "10", text: "CREDIT CARD" },
        { value: "11", text: "LEASING" },
        { value: "12", text: "OVERDRAFT" },
        { value: "13", text: "TWO-WHEELER LOAN" },
        { value: "14", text: "NON-FUNDED CREDIT FACILITY" },
        { value: "15", text: "LOAN AGAINST BANK DEPOSITS" },
        { value: "16", text: "FLEET CARD" },
        { value: "17", text: "Commercial Vehicle Loan" },
        { value: "18", text: "Telco - Wireless" },
        { value: "19", text: "Telco - Broadband" },
        { value: "20", text: "Telco - Landline" },
        { value: "23", text: "GECL Secured" },
        { value: "24", text: "GECL Unsecured" },
        { value: "31", text: "Secured Credit Card" },
        { value: "32", text: "Used Car Loan" },
        { value: "33", text: "Construction Equipment Loan" },
        { value: "34", text: "Tractor Loan" },
        { value: "35", text: "Corporate Credit Card" },
        { value: "36", text: "Kisan Credit Card" },
        { value: "37", text: "Loan on Credit Card" },
        { value: "38", text: "Prime Minister Jaan Dhan Yojana - Overdraft" },
        { value: "39", text: "Mudra Loans - Shishu / Kishor / Tarun" },
        { value: "40", text: "Microfinance - Business Loan" },
        { value: "41", text: "Microfinance - Personal Loan" },
        { value: "42", text: "Microfinance - Housing Loan" },
        { value: "43", text: "Microfinance - Others" },
        {
          value: "44",
          text:
            "Pradhan Mantri Awas Yojana - Credit Link Subsidy Scheme MAY CLSS",
        },
        { value: "45", text: "P2P Personal Loan" },
        { value: "46", text: "P2P Auto Loan" },
        { value: "47", text: "P2P Education Loan" },
        { value: "51", text: "BUSINESS LOAN - GENERAL" },
        {
          value: "52",
          text: "BUSINESS LOAN -PRIORITY SECTOR - SMALL BUSINESS",
        },
        { value: "53", text: "BUSINESS LOAN -PRIORITY SECTOR - AGRICULTURE" },
        { value: "54", text: "BUSINESS LOAN -PRIORITY SECTOR - OTHERS" },
        { value: "55", text: "BUSINESS NON-FUNDED CREDIT FACILITY - GENERAL" },
        {
          value: "56",
          text:
            "BUSINESS NON-FUNDED CREDIT FACILITY - PRIORITY SECTOR - SMALL BUSINESS",
        },
        {
          value: "57",
          text:
            "BUSINESS NON-FUNDED CREDIT FACILITY - PRIORITY SECTOR - AGRICULTURE",
        },
        {
          value: "58",
          text:
            "BUSINESS NON-FUNDED CREDIT FACILITY - PRIORITY SECTOR - OTHERS",
        },
        { value: "59", text: "BUSINESS LOANS AGAINST BANK DEPOSITS" },
        { value: "60", text: "Staff Loan" },
        { value: "61", text: "Business Loan - Unsecured" },
        { value: "00", text: "Others" },
      ],
      account_status: [
        { value: "00", text: "No Suit Filed [00]" },
        { value: "89", text: "Wilful default [89]" },
        { value: "93", text: "Suit Filed(Wilful default) [93]" },
        {
          value: "97",
          text: "Suit Filed(Wilful Default) and Written-off [97]",
        },
        { value: "30", text: "Restructured [30]" },
        { value: "31", text: "Restructured Loan (Govt. Mandated) [31]" },
        { value: "32", text: "Settled [32]" },
        { value: "33", text: "Post (WO) Settled [33]" },
        { value: "34", text: "Account Sold [34]" },
        { value: "35", text: "Written Off and Account Sold [35]" },
        { value: "36", text: "Account Purchased [36]" },
        { value: "37", text: "Account Purchased and Written Off [37]" },
        { value: "38", text: "Account Purchased and Settled [38]" },
        { value: "39", text: "Account Purchased and Restructured [39]" },
        { value: "40", text: "Status Cleared [40]" },
        { value: "41", text: "Restructured Loan [41]" },
        { value: "42", text: "Restructured Loan (Govt. Mandated) [42]" },
        { value: "43", text: "Written-off [43]" },
        { value: "44", text: "Settled [44]" },
        { value: "45", text: "Post (WO) Settled [45]" },
        { value: "46", text: "Account Sold [46]" },
        { value: "47", text: "Written Off and Account Sold [47]" },
        { value: "48", text: "Account Purchased [48]" },
        { value: "49", text: "Account Purchased and Written Off [49]" },
        { value: "50", text: "Account Purchased and Settled [50]" },
        { value: "51", text: "Account Purchased and Restructured [51]" },
        { value: "52", text: "Status Cleared [52]" },
        { value: "53", text: "Suit Filed [53]" },
        { value: "54", text: "Suit Filed and Written-off [54]" },
        { value: "55", text: "Suit Filed and Settled [55]" },
        { value: "56", text: "Suit Filed and Post (WO) Settled [56]" },
        { value: "57", text: "Suit Filed and Account Sold [57]" },
        {
          value: "58",
          text: "Suit Filed and Written Off and Account Sold [58]",
        },
        { value: "59", text: "Suit Filed and Account Purchased [59]" },
        {
          value: "60",
          text: "Suit Filed and Account Purchased and Written Off [60]",
        },

        {
          value: "61",
          text: "Suit Filed and Account Purchased and Settled [61]",
        },
        {
          value: "62",
          text: "Suit Filed and Account Purchased and Restructured [62]",
        },
        { value: "63", text: "Suit Filed and Status Cleared [63]" },
        { value: "64", text: "Wilful Default and Restructured Loan [64]" },
        {
          value: "65",
          text: "Wilful Default and Restructured Loan (Govt. Mandated) [65]",
        },
        { value: "66", text: "Wilful Default and Settled [66]" },
        { value: "67", text: "Wilful Default and Post (WO) Settled [67]" },
        { value: "68", text: "Wilful Default and Account Sold [68]" },
        {
          value: "69",
          text: "Wilful Default and Written Off and Account Sold [69]",
        },
        { value: "70", text: "Wilful Default and Account Purchased [70]" },
        {
          value: "72",
          text: "Wilful Default and Account Purchased and Written Off [72]",
        },
        {
          value: "73",
          text: "Wilful Default and Account Purchased and Settled [73]",
        },
        {
          value: "74",
          text: "Wilful Default and Account Purchased and Restructured [74]",
        },
        { value: "75", text: "Wilful Default and Status Cleared [75]" },
        {
          value: "76",
          text: "Suit filed (Wilful default) and Restructured [76]",
        },
        {
          value: "77",
          text:
            "Suit filed (Wilful default) and Restructured Loan (Govt. Mandated) [77]",
        },
        { value: "79", text: "Suit filed (Wilful default) and Settled [79]" },
        {
          value: "81",
          text: "Suit filed (Wilful default) and Post (WO) Settled [81]",
        },
        {
          value: "85",
          text: "Suit filed (Wilful default) and Account Sold [85]",
        },
        {
          value: "86",
          text:
            "Suit filed (Wilful default) and Written Off and Account Sold [86]",
        },
        {
          value: "87",
          text: "Suit filed (Wilful default) and Account Purchased [87]",
        },
        {
          value: "88",
          text:
            "Suit filed (Wilful default) and Account Purchased and Written Off [88]",
        },
        {
          value: "94",
          text:
            "Suit filed (Wilful default) and Account Purchased and Settled [94]",
        },
        {
          value: "90",
          text:
            "Suit filed (Wilful default) and Account Purchased and Restructured [90]",
        },
        {
          value: "91",
          text: "Suit filed (Wilful default) and Status Cleared [91]",
        },
        { value: "13", text: "Paid or closed account/zero balance [13]" },
        { value: "14", text: "CLOSED [14]" },
        { value: "15", text: "CLOSED [15]" },
        { value: "16", text: "CLOSED [16]" },
        { value: "17", text: "CLOSED [17]" },
        { value: "12", text: "CLOSED [12]" },
        { value: "11", text: "CURRENT ACCOUNT [11]" },
        { value: "71", text: "Account 30 days past the due date [71]" },
        { value: "78", text: "Account 60 days past the due date [78]" },
        { value: "80", text: "Account 90 days past the due date [80]" },
        { value: "82", text: "Account 120 days past the due date [82]" },
        { value: "83", text: "Account 150 days past the due date [83]" },

        { value: "84", text: "Account 180 days past the due date [84]" },
        { value: "DEFAULTVALUE", text: "ACTIVE" },
        { value: "21", text: "ACTIVE [21]" },
        { value: "22", text: "ACTIVE [22]" },
        { value: "23", text: "ACTIVE [23]" },
        { value: "24", text: "ACTIVE [24]" },
        { value: "25", text: "ACTIVE [25]" },
        { value: "131", text: "Restructured due to natural calamity [131]" },
        { value: "130", text: "Restructured due to COVID-19 [130]" },
      ],
    };
  },
  components: {
    CibilAllDetailsDialog: () =>
      import("@/components/new-customer/dialog/CibilAllDetailsDialog.vue"),
  },
  computed: {
    cibil() {
      // return cibil_res ? cibil_res : "";
      return this.cibil_data ? this.cibil_data : "";
    },
  },
  mounted() {
    this.getCibilData();
  },
  methods: {
    checkForValue(value) {
      if (value) {
        if (value.text) return value.text;
        else return "-";
      } else {
        return "-";
      }
    },
    checkForValueEmpty(value) {
      if (value) {
        if (value.text) return value.text;
        else return "";
      } else {
        return "";
      }
    },
    checkForValueGender(value) {
      if (value) {
        if (value.text) {
          if (value.text == 1) {
            return "Male";
          } else if (value.text == 2) {
            return "Female";
          } else if (value.text == 3) {
            return "Transgender";
          }
        } else return "";
      } else {
        return "";
      }
    },
    checkForAccountTypeValue(value) {
      if (value) {
        if (value.text) {
          let result = "";
          this.account_type.filter((item) => {
            // console.log(item.text);
            if (value.text == item.value) {
              // console.log(item.text);
              return (result = item.text);
            }
          });
          return result;
          // return value.text;
        } else return "";
      } else {
        return "";
      }
    },
    checkForAccountStatusValue(value) {
      if (value) {
        if (value.text) {
          let result = "";
          this.account_status.filter((item) => {
            // console.log(item.text);
            if (value.text == item.value) {
              // console.log(item.text);
              return (result = item.text);
            }
          });
          return result;
          // return value.text;
        } else return "";
      } else {
        return "";
      }
    },
    checkForDateValue(value) {
      if (value) {
        if (value.text) {
          let year = value.text.slice(0, 4);
          let month = value.text.slice(4, 6);
          let date = value.text.slice(6, 8);
          return `${date}-${month}-${year}`;
        } else return "";
      } else {
        return "";
      }
    },
    gotToCompleteDetails() {
      console.log("id", this.$route.params.id);

      let route = this.$router.resolve({
        name: "compelete-cibil-report",
        params: {
          id: this.$route.params.id,
        },
      });
      window.open(route.href, "_blank");
    },
    getCibilData() {
      const self = this;
      self.page_loader = true;
      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };

      const successHandler = (response) => {
        if (response.data.success) {
          this.cibil_data = response.data.data;
          console.log("CIBIL date", response);
          self.page_loader = false;
        }
      };
      const finallyHandler = () => {
        self.page_loader = false;
      };

      self.request_GET(
        self,
        self.$urls.GET_CIBIL_INFO,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
